<template>
<Defaultlayout :userprofile="userprofile">
   <section class="order-status">
    <div class="container">
      <div class="successfull-order-info text-center">
          <div class="order-status-icon">
            <img src="../../assets/images/svgs/payment-failed.svg" alt="payment-failed">
          </div>
          <h2 class="text-black">Payment Failed!</h2>
          <p class="mb-0 cl-gray gm-book">{{this.$route.query.errmsg}}</p>
      </div>

      <div class="row justify-content-center btn-row">
        <router-link :to="'/'" :style="styleObject" class="btn btn-outline-danger">
            Go to Home
        </router-link>
        <router-link :to="'/cart'" :style="styleObject" class="btn btn-outline-danger">
            Go to Cart
        </router-link>
      </div>
    </div>
  </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
export default {
  name: "PaymentInfo",
  components: {
    Defaultlayout
  },
  props: {
    errmsg: {
      type: String
    }
  },
  data() {
    return {
      userprofile: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
  computed: {
    styleObject: function() {
      return {
        '--button-color': localStorage.getItem("button_default_color"),
        '--button-border-color': localStorage.getItem("button_default_color"),
        '--button-color--hover': localStorage.getItem("css_button_customer_hover_font"),
        '--button-background-color--hover': localStorage.getItem("button_hover_color"),
        '--button-border-color--hover': localStorage.getItem("css_button_customer_hover_font"),
      }
    }
  },
  methods:{
    gotohome(){
      this.$router.push("/");
    }
  }
};
</script>
<style type="text/css" scoped>
  .btn-outline-danger {
    color: var(--button-color);
    border-color: var(--button-border-color);
  }

  .btn-outline-danger:hover {
    color: var(--button-color--hover);
    background-color: var(--button-background-color--hover);
    border-color: var(--button-border-color--hover);
  }
</style>